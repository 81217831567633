import React, { useEffect, useState } from 'react';
import { useAppStateAPI } from '../common/AppStateAPI';
import * as ServerAPI from '../common/ServerAPI';
import { APIData } from '../common/DataTypes';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
import { Link, useSearchParams } from 'react-router-dom';
import { PageLinks } from '../common/Constants';


const Product: React.FC = () => {
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [vehicles, setVehicles] = useState<APIData.Vehicle[]>([]);
    const [collectionType, setCollectionType] = useState<APIData.VehicleCollectionType>();
    const [searchParams] = useSearchParams();
    const collection_type_id = searchParams.get('collection_type_id');

    useEffect(() => {
        if (collection_type_id) {
            fetchGetVehicleData(parseInt(collection_type_id));
            fetchCollectionType(parseInt(collection_type_id));
        } else {
            fetchGetVehicleData();
            setCollectionType(undefined);
        }
    }, [collection_type_id]);

    const fetchGetVehicleData = (collectionTypeID?: number) => {
        addProcessingRequests();
        try {
            ServerAPI.GetVehicleFilter({ vehicle_collection_type_id: collectionTypeID }).then(response => {
                if (response.success && response.vehicles) {
                    setVehicles(response.vehicles);
                } else if (response.message) {
                    showToastMsg("error", response.message);
                } else {
                    showToastMsg("error", "Error occurred while processing the request");
                }
            });
        } catch (error) {
            showToastMsg("error", "An unexpected error occurred");
        } finally {
            reduceProcessingRequests();
        }
    };

    const fetchCollectionType = (collectionTypeID: number) => {
        addProcessingRequests();
        try {
            ServerAPI.getVehicleCollectionTypes(collectionTypeID).then(response => {
                if (response.success && response.vehicle_collection_types && response.vehicle_collection_types.length > 0) {
                    setCollectionType(response.vehicle_collection_types[0]);
                } else if (response.message) {
                    showToastMsg("error", response.message);
                } else {
                    showToastMsg("error", "Error occurred while processing the request");
                }
            });
        } catch (error) {
            showToastMsg("error", "An unexpected error occurred");
        } finally {
            reduceProcessingRequests();
        }
    }

    return (
        <>
            <Preloader />

            {/* Start Header Section */}
            <Header />
            <div className="container">
                <div className="common-page-title">
                    <h3 className="page-title">{collectionType ? collectionType.vehicle_collection_type_name : 'Cars'} Collections</h3>
                </div>
                <div className="primary-color-border"></div>
            </div>
            {/* End common page title */}

            {/* Start Blog */}
            <div className="ak-height-50 ak-height-lg-50"></div>
            <div className="container">
                {vehicles.length > 0 ?
                    <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-5 g-lg-4 pagination-wrapper">
                        {vehicles.map((vehicle) => (
                            <div className="col" key={vehicle.vehicle_id}> {/* Each vehicle in a separate column */}
                                <Link to={`${PageLinks.PRODUCT_DETAIL}/${vehicle.vehicle_id}`}>
                                    <img
                                        src={vehicle.vehicle_default_image}
                                        alt={vehicle.vehicle_model_name || 'Vehicle Image'}
                                        style={{ width: '500px', height: 'auto', objectFit: 'cover', minHeight: '300px' }} // Set width to 500px and height to 500px
                                    />
                                </Link>

                                <div className="blog-body-info">
                                    <Link
                                        key={vehicle.vehicle_id}
                                        to={`${PageLinks.PRODUCT_DETAIL}/${vehicle.vehicle_id}`}
                                        className="blog-title"
                                        style={{ color: 'white', fontWeight: 'bold' }} // Inline styles
                                    >
                                        {/* Display the brand name for the current vehicle */}
                                    </Link>
                                </div>
                                <div className="blog-footer-info">
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        {vehicle.vehicle_title}
                                    </div>
                                    <Link to={`${PageLinks.PRODUCT_DETAIL}/${vehicle.vehicle_id}`} className="more-btn">READ MORE</Link>
                                </div>
                            </div>
                        ))}
                        <div id="pagination-container" className="pagination-style"></div>
                    </div>
                    :
                    <div className="text-center">
                        <h3>No vehicles found for this collection</h3>
                    </div>
                }


                <div id="pagination-container" className="pagination-style"></div>
            </div>
            {/* End Blog */}


            {/* Start Footer */}
            <Footer />
            {/* End Footer */}
            <span className="ak-scrollup">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
                        fill="currentColor" />
                </svg>
            </span>

        </>
    );
};

export default Product;
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Preloader from '../components/Preloader';

const ContactUs: React.FC = () => {

    return (
        <>
            {/* Preloader */}
            <Preloader/>
            {/* Start Header Section */}
            <Header />
           {/* End Header Section */}

            {/* Start form */}
            <div className="ak-height-20 ak-height-lg-20"></div>
            <div className="container">
                <div className="ak-section-heading ak-style-1">
                    <div className="background-text">Contact</div>
                    <h2 className="ak-section-title">Contact</h2>
                    <p className="ak-section-subtitle">Let Kayrah Cars be your gateway to automotive excellence.
                    </p>
                </div>
                <div className="ak-height-25 ak-height-lg-20"></div>
                <div className="contact-content">
                    <div className="contact-title-section">
                        <div className="contact-info">
                            <div className="right-info">
                                <div className="info-card" style={{ padding: '40px' }}>
                                    <p> Mail</p>
                                    <div className="d-flex gap-2">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17"
                                                fill="none">
                                                <g clip-path="url(#clip0_288_1253)">
                                                    <path
                                                        d="M18.9875 16.3572C19.4371 16.3572 19.8266 16.2087 20.1578 15.9156L14.4914 10.2491C14.3555 10.3464 14.2237 10.4411 14.0992 10.5311C13.6752 10.8435 13.331 11.0873 13.0668 11.262C12.8025 11.4371 12.451 11.6156 12.0121 11.7978C11.573 11.9802 11.1639 12.0711 10.7843 12.0711H10.7732H10.7621C10.3825 12.0711 9.97343 11.9803 9.53432 11.7978C9.0952 11.6156 8.74364 11.4371 8.47966 11.262C8.21542 11.0873 7.87145 10.8436 7.44719 10.5311C7.32896 10.4444 7.19784 10.3493 7.05608 10.2476L1.38867 15.9156C1.71979 16.2087 2.10959 16.3572 2.55913 16.3572H18.9875Z"
                                                        fill="white" />
                                                    <path
                                                        d="M1.90079 6.68088C1.47678 6.3982 1.10078 6.07445 0.773438 5.70978V14.331L5.76771 9.33668C4.76857 8.63914 3.48123 7.75489 1.90079 6.68088Z"
                                                        fill="white" />
                                                    <path
                                                        d="M19.6575 6.68088C18.1373 7.7098 16.8453 8.59557 15.7812 9.33868L20.7735 14.3312V5.70978C20.4534 6.06712 20.0815 6.39065 19.6575 6.68088Z"
                                                        fill="white" />
                                                    <path
                                                        d="M18.9874 0.642822H2.55904C1.9859 0.642822 1.54527 0.836361 1.23659 1.22304C0.927651 1.6099 0.773438 2.09371 0.773438 2.67392C0.773438 3.14259 0.978087 3.6504 1.3872 4.1975C1.79632 4.74438 2.23166 5.17395 2.69299 5.4864C2.9459 5.66509 3.70858 6.19531 4.98103 7.07687C5.66793 7.55287 6.26528 7.96776 6.77861 8.32579C7.21616 8.63066 7.59351 8.89468 7.90506 9.11378C7.94082 9.13887 7.99707 9.1791 8.07172 9.23248C8.15215 9.29025 8.25393 9.36356 8.3795 9.45423C8.62129 9.62911 8.82217 9.77046 8.98215 9.87845C9.14192 9.98648 9.3355 10.1071 9.56259 10.2411C9.7895 10.3749 10.0035 10.4756 10.2044 10.5425C10.4053 10.6094 10.5913 10.6429 10.7624 10.6429H10.7735H10.7846C10.9557 10.6429 11.1417 10.6094 11.3426 10.5425C11.5435 10.4756 11.7573 10.3752 11.9844 10.2411C12.2113 10.1071 12.4046 9.98623 12.5649 9.87845C12.7249 9.77046 12.9257 9.62914 13.1676 9.45423C13.2929 9.36356 13.3946 9.29021 13.4751 9.23266C13.5498 9.17907 13.606 9.13909 13.642 9.11378C13.8847 8.94489 14.2629 8.68197 14.7714 8.32888C15.6967 7.68599 17.0594 6.73976 18.8652 5.4864C19.4083 5.10706 19.862 4.64929 20.2267 4.11373C20.5907 3.57818 20.7732 3.01641 20.7732 2.4286C20.7732 1.9375 20.5963 1.51727 20.2432 1.16726C19.8896 0.817697 19.471 0.642822 18.9874 0.642822Z"
                                                        fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_288_1253">
                                                        <rect width="21" height="17" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div>
                                            <p>Harsha@kayrahcars.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-card"  style={{ padding: '40px' }}>
                                    <p> Location</p>
                                    <div className="d-flex gap-2 ">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"
                                                fill="none">
                                                <path
                                                    d="M7 0C3.14027 0 0 2.99074 0 6.66668C0 7.7702 0.289693 8.86434 0.840408 9.8348L6.6172 17.7852C6.69411 17.9178 6.84065 18 7 18C7.15935 18 7.30589 17.9178 7.3828 17.7852L13.1617 9.83152C13.7103 8.86434 14 7.77016 14 6.66664C14 2.99074 10.8597 0 7 0ZM7 10C5.07014 10 3.50002 8.50465 3.50002 6.66668C3.50002 4.82871 5.07014 3.33336 7 3.33336C8.92986 3.33336 10.5 4.82871 10.5 6.66668C10.5 8.50465 8.92986 10 7 10Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p>JT030047 Compass Building <br />
                                                AL Hamra Industrial Zone - FZ <br />
                                                Ras AI Khaimah <br />
                                                United Arab Emirates</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-card"  style={{ padding: '40px' }}>
                                    <p className="phone"> Phone</p>
                                    <div className="d-flex gap-2 align-items-center">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <g clip-path="url(#clip0_1774_2)">
                                                    <path
                                                        d="M18.7604 13.6277C17.536 13.6277 16.3337 13.4362 15.1943 13.0597C14.6361 12.8693 13.9498 13.044 13.609 13.3939L11.3602 15.0915C8.75227 13.6994 7.14581 12.0934 5.77268 9.50505L7.42034 7.31484C7.84841 6.88734 8.00195 6.26286 7.818 5.67693C7.43987 4.53161 7.24779 3.3299 7.24779 2.1049C7.24784 1.21995 6.52789 0.5 5.64299 0.5H1.97008C1.08518 0.5 0.365234 1.21995 0.365234 2.10484C0.365234 12.2481 8.61721 20.5 18.7604 20.5C19.6453 20.5 20.3653 19.7801 20.3653 18.8952V15.2325C20.3652 14.3477 19.6453 13.6277 18.7604 13.6277Z"
                                                        fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1774_2">
                                                        <rect width="20" height="20" fill="white" transform="translate(0.365234 0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div>
                                            <p>+971 58 550 4939</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ak-height-25 ak-height-lg-20"></div>
                    <div className="contact-form">
                        <div>
                            <h5 className="mb-3">How can we help?</h5>
                            <p>Let Kayrah Cars be your gateway to automotive excellence.</p>
                            <div className="ak-height-45 ak-height-lg-30"></div>
                        </div>
                        <div id="ak-alert"></div>
                        <form method="POST" id="contact-form">
                            <div className="from-inputs">
                                <div className="type_1">
                                    <label htmlFor="name" className="form-label">Full Name</label>
                                    <input type="text" name="name" id="name" required />
                                </div>
                                <div className="type_1">
                                    <label htmlFor="email" className="form-label">Email*</label>
                                    <input type="email" name="email" id="email" required />
                                </div>
                            </div>
                            <div className="from-inputs ">
                                <div className="type_1">
                                    <label htmlFor="topic" className="form-label">Which topic best matches your question?</label>
                                    <input type="text" name="topic" id="topic" required />
                                </div>
                                <div className="type_1">
                                    <label htmlFor="subject" className="form-label">Subject</label>
                                    <input type="text" name="subject" id="subject" required />
                                </div>
                            </div>
                            <div className="from-textarea">
                                <div className="type_1">
                                    <label htmlFor="msg" className="form-label">Your Message*</label>
                                    <textarea name="msg" rows={5} id="msg" required></textarea>
                                </div>
                            </div>
                            <div className="ak-height-40 ak-height-lg-20"></div>
                            <button type="submit" id="submit" name="submit" className="common-btn">SEND MESSAGE</button>
                        </form>
                    </div>
                </div>
            </div>
            {/* End form  */}
            <section className="container">
            </section>

            {/* Start Footer */}
            <Footer />
            {/* End Footer */}
            <span className="ak-scrollup">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
                        fill="currentColor" />
                </svg>
            </span>
           
        </>
    );
};

export default ContactUs;
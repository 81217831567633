import React, { ReactNode, createContext, useContext, useReducer } from 'react';
import { AlertType } from './Constants';
import { APIData } from './DataTypes';

interface GlobalState {
    showSuccessPopup: boolean;
    successTitle: string;
    successMessage: string;
    processingRequests: number;
    showToast: boolean;
    toastSeverity: AlertType;
    toastMessage: string;
}

interface ProviderAction {
    type: string;
    showSuccessPopup?: boolean;
    successTitle?: string;
    successMessage?: string;
    processingRequests?: number;
    showToast?: boolean;
    toastSeverity?: AlertType;
    toastMessage?: string;
}


const initialState: GlobalState = {
    showSuccessPopup: false,
    successTitle: '',
    successMessage: '',
    processingRequests: 0,
    showToast: false,
    toastSeverity: 'info',
    toastMessage: '',
}


const AppStateContext = createContext<{
    state: GlobalState;
    dispatch: React.Dispatch<ProviderAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});

export const StateActionTypes = {
    SET_SHOW_SUCCESS_POPUP: "SET_SHOW_SUCCESS_POPUP",
    ADD_PROCESSING_REQUESTS: "ADD_PROCESSING_REQUESTS",
    REDUCE_PROCESSING_REQUESTS: "REDUCE_PROCESSING_REQUESTS",
    SET_SHOW_TOAST: "SET_SHOW_TOAST",
}

const reducer = (state: GlobalState = initialState, action: ProviderAction): GlobalState => {
    switch (action.type) {
        case StateActionTypes.SET_SHOW_SUCCESS_POPUP:
            return {
                ...state, showSuccessPopup: action.showSuccessPopup || false, successTitle: action.successTitle || '', successMessage: action.successMessage || ''
            }
        case StateActionTypes.ADD_PROCESSING_REQUESTS:
            return {
                ...state, processingRequests: state.processingRequests + 1
            }
        case StateActionTypes.REDUCE_PROCESSING_REQUESTS:
            return {
                ...state, processingRequests: state.processingRequests - 1
            }
        case StateActionTypes.SET_SHOW_TOAST:
            return {
                ...state, showToast: action.showToast || false, toastSeverity: action.toastSeverity || 'info', toastMessage: action.toastMessage || ''
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

type AppStateProviderProps = {
    children: ReactNode;
};

export const AppStateProvider = ({ children }: AppStateProviderProps): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    )
}

export const useAppState = (): { state: GlobalState, dispatch: React.Dispatch<ProviderAction> } => useContext(AppStateContext);
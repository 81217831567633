import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';
import Preloader from '../components/Preloader';
import { Link } from 'react-router-dom';

const AboutUs: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Mission');

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    return (
        <>
            {/* Preloader */}
            <Preloader />
            {/* Header */}
            <Header />
            {/* Page Title */}
            <div data-src="assets/img/about-page-title.jpg" className="ak-bg">
                <div className="container">
                    <div className="common-page-title">
                        <h3 className="page-title">About Us</h3>
                        <div className="d-flex gap-2 align-items-center">
                            <p>Home /</p>
                            <Link to={PageLinks.ABOUT_US} className="text-hover-animation" style={{ color: 'white' }}>
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Tab Section */}
            <div className="container">
                <div className="company-tab">
                    <ul className="tabs">
                        <li
                            className={activeTab === 'Mission' ? 'active-tab' : ''}
                            onClick={() => handleTabClick('Mission')}
                        >
                            Mission
                        </li>
                        <li
                            className={activeTab === 'Vision' ? 'active-tab' : ''}
                            onClick={() => handleTabClick('Vision')}
                        >
                            Vision
                        </li>
                    </ul>
                    <div>

                        <div className="list">
                            <div className="ak-section-heading ak-style-1">
                                <div className="background-text">About Company</div>
                                {activeTab === 'Mission' && (
                                    <div className="mission-container">
                                        <div className="mission-text">
                                            <h3 className="desp">
                                                To be the <b>leading automobile dealer,</b> offering an
                                                unparalleled selection of high-end vehicles and exceptional
                                                customer service, ensuring every client experiences the
                                                pinnacle of automotive excellence and satisfaction.
                                            </h3>
                                        </div>
                                        <div className="choose-us-img">
                                            <img src="assets/img/choose-us.png" alt="..." />
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'Vision' && (
                                    <div className="vision-container">
                                        <div className="vision-text">
                                            <h3 className="desp">
                                                To provide discerning clients with the finest luxury vehicles
                                                through a curated selection and personalized service, fostering
                                                lasting relationships by delivering unmatched quality, support,
                                                and a seamless car-buying experience from selection to
                                                after-sales care.
                                            </h3>
                                        </div>
                                        <div className="choose-us-img">
                                            <img src="assets/img/choose-us.png" alt="..." />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Us Section */}
            {/* Call to Action Section */}
            <div className="ak-height-40 ak-height-lg-40"></div>
            <div className="container">
                <div className="cta">
                    <span className="border-pr"></span>
                    <span className="border-wh"></span>
                    <div className="cta-info">
                        <h2 className="cta-title">Get in touch with our experts</h2>
                        <Link to={PageLinks.CONTACT_US}>
                            <div className="cta-btn">
                                <img src="assets/img/phone.svg" alt="..." />
                                <span className="ms-2"> GET IN TOUCH WITH US</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <Footer />
            {/* Scroll to Top */}
            <span className="ak-scrollup">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="(link unavailable)">
                    <path d="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z" fill="currentColor" />
                </svg>
            </span>
        </>
    );
};
export default AboutUs;

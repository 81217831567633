
import AppNavigate from './AppNavigate';
import { AppStateProvider } from './common/AppState';

function App() {
  return (
    <div className="App">
      <AppStateProvider>
        <AppNavigate />
      </AppStateProvider>
    </div>
  );
}

export default App;

import React from 'react';
import Index from './pages/Index';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import ProductDetails from './pages/ProductDetails';
// import Service from './pages/Service';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Product from './pages/Products';
import { PageLinks } from './common/Constants';

const AppNavigate: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Index />} />
                <Route path={PageLinks.ABOUT_US} element={<AboutUs />} />
                <Route path={PageLinks.CONTACT_US} element={<ContactUs />} />
                <Route path={PageLinks.PRODUCTS} element={<Product />} />
                {/* <Route path='/service' element={<Service />} /> */}
                <Route path={PageLinks.PRODUCT_DETAIL + '/:vehicle_id'} element={<ProductDetails />} /> {/* Include vehicle_id in the route */}
            </Routes>
        </Router>
    );
}

export default AppNavigate;
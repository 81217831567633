import React from 'react';
import { Link, Route } from 'react-router-dom';
import { PageLinks } from '../common/Constants';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
const Index: React.FC = () => {

    return (
        <>
            <Preloader />
            {/* Start Header Section  */}
            <Header />
            {/* End Header Section */}
            {/* Start Hero */}
            <section className="ak-slider ak-slider-hero-1">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="ak-hero ak-style1 slide-inner">
                            <img src="assets/img/hero_slider_bg_1.png" className="ak-hero-bg ak-bg object-cover" alt="..." />
                            <div className="container">
                                <div className="hero-slider-info">
                                    <div className="slider-info">
                                        <div className="hero-title">
                                            <h1 className="hero-main-title" data-swiper-parallax="300">Your Gateway to Automotive
                                                Excellence Starts Here</h1>

                                        </div>
                                        <div className="ak-height-45 ak-height-lg-30"></div>
                                        <div data-swiper-parallax="300">
                                            <Link
                                                to={PageLinks.CONTACT_US}
                                                className="text-hover-animation"
                                                style={{ color: 'white' }}
                                            >
                                                <div className="common-btn"> ENQUIRY</div>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ak-hero ak-style1 slide-inner">
                            <img src="assets/img/hero_slider_bg_2.png" className="ak-hero-bg ak-bg object-cover" alt="..." />
                            <div className="container">
                                <div className="hero-slider-info">
                                    <div className="slider-info">
                                        <div className="hero-title">
                                            <h1 className="hero-main-title " data-swiper-parallax="300">Find Your Perfect Ride with
                                                Unmatched Service and Selection</h1>

                                        </div>
                                        <div className="ak-height-45 ak-height-lg-30"></div>
                                        <div data-swiper-parallax="300">
                                            <Link
                                                to={PageLinks.CONTACT_US}
                                                className="text-hover-animation"
                                                style={{ color: 'white' }}
                                            >
                                                <div className="common-btn">ENQUIRY</div>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ak-hero ak-style1  slide-inner">
                            <img src="assets/img/hero_slider_bg_3.png" className="ak-hero-bg ak-bg object-cover" alt="..." />
                            <div className="container">
                                <div className="hero-slider-info">
                                    <div className="slider-info">
                                        <div className="hero-title">
                                            <h1 className="hero-main-title" data-swiper-parallax="300">Drive Your Dreams: Discover
                                                automobiles at Kayrah Cars</h1>

                                        </div>
                                        <div className="ak-height-45 ak-height-lg-30"></div>
                                        <div data-swiper-parallax="300">
                                            <Link
                                                to={PageLinks.CONTACT_US}
                                                className="text-hover-animation"
                                                style={{ color: 'white' }}
                                            >
                                                <div className="common-btn">ENQUIRY</div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ak-swiper-controll-hero-1">
                    <div className="ak-swiper-navigation-wrap">
                        <div className="ak-swiper-button-prev">
                            <div className="hero-swiper-prev">
                                <div className="btn-cricle ak-white-bg-1"></div>
                                <div className="btn-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="41" viewBox="0 0 29 41"
                                        fill="none">
                                        <path
                                            d="M1.82581 20.0839L7.72307 14.1866C7.93491 13.9392 8.3072 13.9104 8.55457 14.1223C8.80194 14.3341 8.83078 14.7064 8.61889 14.9538C8.59912 14.9769 8.57763 14.9984 8.55457 15.0181L3.66574 19.9129H20.0831C20.4088 19.9129 20.6729 20.1769 20.6729 20.5026C20.6729 20.8284 20.4088 21.0924 20.0831 21.0924H3.66574L8.55457 25.9812C8.80194 26.193 8.83078 26.5653 8.61889 26.8127C8.40699 27.0601 8.03475 27.0889 7.78738 26.877C7.76432 26.8572 7.74278 26.8358 7.72307 26.8127L1.82575 20.9154C1.59714 20.6854 1.59714 20.314 1.82581 20.0839Z"
                                            fill="#fff" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="ak-swiper-button-next">
                            <div className="hero-swiper-next">
                                <div className="btn-cricle ak-white-bg-1"></div>
                                <div className="btn-arrow ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="41" viewBox="0 0 29 41"
                                        fill="none">
                                        <path
                                            d="M20.5013 20.0839L14.6041 14.1866C14.3922 13.9392 14.0199 13.9104 13.7726 14.1223C13.5252 14.3341 13.4964 14.7064 13.7083 14.9538C13.728 14.9769 13.7495 14.9984 13.7726 15.0181L18.6614 19.9129H2.24401C1.91834 19.9129 1.6543 20.1769 1.6543 20.5026C1.6543 20.8284 1.91834 21.0924 2.24401 21.0924H18.6614L13.7726 25.9812C13.5252 26.193 13.4964 26.5653 13.7083 26.8127C13.9202 27.0601 14.2924 27.0889 14.5398 26.877C14.5628 26.8572 14.5844 26.8358 14.6041 26.8127L20.5014 20.9154C20.73 20.6854 20.73 20.314 20.5013 20.0839Z"
                                            fill="#fff" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-pagination">
                    <div className="hero-swiper-pagination"></div>
                </div>
                <div className="social-hero">
                    <a href="" className="social-icon1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path
                                d="M9.27491 8.83093L2.5 3.00265L2.5 4.38377L8.38256 9.44446L2.5 13.4864L2.5 18.1484L11.3955 12.0361L18.5 18.1484L18.5 16.7672L12.2878 11.4229L18.5 7.15432L18.5 2.49239L9.27491 8.83127L9.27491 8.83093ZM11.4738 10.7227L10.5881 11.342L3.53974 16.2695L3.53974 14.1481L9.22795 10.1715L10.1137 9.55219L17.5075 4.38311L17.5075 6.50455L11.4742 10.7227L11.4738 10.7227Z"
                                fill="white" />
                        </svg>
                    </a>
                    <a href="" className="social-icon1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <g clip-path="url(#clip0_64_135)">
                                <path
                                    d="M16.5 0.324375L16.4993 0.320374L10.6313 0.320374C7.76068 0.320374 5.54935 0.938375 5.54935 4.29437C5.54935 5.90771 6.43468 6.99037 7.27401 7.43237L7.27401 7.47904L5.81735 7.47904L5.81735 10.661L16.4993 10.661L16.4993 7.34771L11.21 7.34771C9.81735 7.34771 8.47068 7.08371 8.47068 5.35904C8.47068 3.65971 10.06 3.63437 11.2993 3.63437L16.5 3.63437L16.5 0.324375Z"
                                    fill="white" />
                                <path d="M5.81799 16.0564L5.81799 12.739L16.5 12.739L16.5 16.0564L5.81799 16.0564Z"
                                    fill="white" />
                                <path
                                    d="M0.5 14.399C0.5 15.4597 1.36067 16.3204 2.42133 16.3204C3.482 16.3204 4.36067 15.4597 4.36067 14.399C4.36067 13.3384 3.482 12.4777 2.42133 12.4777C1.36067 12.4784 0.5 13.339 0.5 14.399Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_64_135">
                                    <rect width="16" height="16" fill="white" transform="translate(0.5 16.3204) rotate(-90)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a href="" className="social-icon1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <g clip-path="url(#clip0_64_133)">
                                <path
                                    d="M16.5 7.08128L9.20218 7.08128L9.20218 4.63269L6.35725 4.26533L6.35725 7.08128L4.54118 7.08128C3.71776 7.08128 3.15661 6.85356 3.15661 5.67145L3.15599 4.16622L0.611384 4.16622C0.577556 4.42653 0.5 5.32007 0.5 6.36007C0.5 8.53175 1.82557 10.0185 4.25942 10.0185L6.35725 10.0185L6.35725 12.4745L9.20218 12.4745L9.20218 10.0185L16.5 10.0185L16.5 7.08128Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_64_133">
                                    <rect width="16" height="16" fill="white" transform="translate(0.5 16.3204) rotate(-90)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <div className="social-horizontal"></div>
                    <h6 className="social-link">FOLLOW US</h6>
                </div>
            </section >
            {/* End Hero */}
            {/* Start Service Progress */}
            <div className="ak-height-40 ak-height-lg-40"></div>
            <section className="container">
                <div className="row  row-cols-1 row-cols-xl-3 g-4 ">
                    <div className="service-progress-card">
                        <div className="progress-item">
                            <h4 className="ak-stroke-number color-white">01</h4>
                            <div className="ak-border-width"></div>
                        </div>
                        <div className="service-item">
                            <div className="heartbeat-icon">
                                <a href="">
                                    <span className="ak-heartbeat-btn"><img src="assets/img/speedome.svg" alt="hello" /></span>
                                </a>
                            </div>
                            <div className="service-info">
                                <h4 className="title">Your Gateway to Automotive Excellence Starts Here</h4>

                            </div>
                        </div>
                    </div>
                    <div className="service-progress-card" data-aos-delay="100">
                        <div className="progress-item">
                            <h4 className="ak-stroke-number color-white">02</h4>
                            <div className="ak-border-width"></div>
                        </div>
                        <div className="service-item">
                            <div className="heartbeat-icon">
                                <a href="">
                                    <span className="ak-heartbeat-btn"><img src="assets/img/car-repair.svg" alt="..." /></span>
                                </a>
                            </div>
                            <div className="service-info">
                                <h4 className="title">Find Your Perfect Ride with Unmatched Service and Selection</h4>

                            </div>
                        </div>
                    </div>
                    <div className="service-progress-card" data-aos-delay="150">
                        <div className="progress-item">
                            <h4 className="ak-stroke-number color-white">03</h4>
                            <div className="ak-border-width"></div>
                        </div>
                        <div className="service-item">
                            <div className="heartbeat-icon">
                                <a href="">
                                    <span className="ak-heartbeat-btn"><img src="assets/img/car.svg" alt="..." /></span>
                                </a>
                            </div>
                            <div className="service-info">
                                <h4 className="title">Drive Your Dreams: Discover automobiles at Kayrah Cars</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Service Progress */}
            {/* Start Why Choose Us */}
            <div className="ak-height-40 ak-height-lg-40"></div>
            <section className="container">
                <div className="blog-feature">
                    <div className="feature-content" data-aos-delay="600">
                        <h2 className="ak-section-title">Why Us</h2>
                        <h4 className="title">Unmatched Selection:</h4>
                        <p className="desp">Our inventory features a wide range of new vehicles, meticulously inspected to ensure
                            the highest quality.
                        </p>
                    </div>
                    <div className="feature-img" data-aos-delay="600">
                        <img src="assets/img/blog-show.jpg" className="ak-bg" alt="..." />
                    </div>
                </div>
            </section>
            {/* <Start Blog */}
            <div className="ak-height-50 ak-height-lg-50"></div>
            <div className="container">
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-5 g-lg-4 pagination-wrapper">
                    <div className="col">
                        <div className="blog-card" >
                            {/* <Link
                                to={PageLinks.SINGLE_BLOG}
                                className="blog-header-info"
                            > */}
                            <div className="blog-header-info">
                                <img src="assets/img/blog_4.jpg" alt="..." />
                            </div>
                            {/* </Link> */}

                            <div className="blog-body-info">
                                <p className="blog-text">Seamless logistics support:</p>
                                {/* <Link
                                    to={PageLinks.SINGLE_BLOG}
                                    className="blog-title"
                                > */}
                                We coordinate the safe and timely delivery of
                                vehicles, utilizing state-of-the-art transport solutions to meet your requirements.
                                {/* </Link> */}
                            </div>

                        </div>
                    </div>
                    <div className="col">
                        <div className="blog-card" >
                            {/* <Link
                                to={PageLinks.SINGLE_BLOG}
                                className="blog-header-info"
                            > */}
                            <div className="blog-header-info">
                                <img src="assets/img/blog_5.jpg" alt="..." />
                            </div>
                            {/* </Link> */}

                            <div className="blog-body-info">
                                <p className="blog-text">Customs and Documentation Handling:</p>
                                {/* <Link
                                    to={PageLinks.SINGLE_BLOG}
                                    className="blog-header-info"
                                > */}
                                <div className="blog-header-info">
                                    Our experts manage all customs clearance and
                                    necessary documentation, ensuring a hassle-free process for international and domestic
                                    deliveries.
                                </div>
                                {/* </Link> */}

                            </div>

                        </div>
                    </div>
                    <div className="col">
                        <div className="blog-card">
                            {/* <Link
                                to={PageLinks.SINGLE_BLOG}
                                className="text-hover-animation"
                            > */}
                            <div className="blog-header-info">  <img src="assets/img/blog_6.jpg" alt="..." /></div>
                            {/* </Link> */}


                            <div className="blog-body-info">
                                <p className="blog-text">After-Sales Support:</p>
                                {/* <Link
                                    to={PageLinks.SINGLE_BLOG}
                                    className="blog-title"
                                > */}
                                <div className="menu-text">Our commitment to you doesn’t end with the sale.
                                    We provide comprehensive after-sales support, including maintenance and repair services, to
                                    keep your luxury vehicle in pristine condition.</div>
                                {/* </Link> */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ak-section-heading ak-style-1"> */}
                <div className="ak-height-60 ak-height-lg-30"></div>
                {/* <div className="background-text ak-style-1">About Us</div> */}
                <div className="ak-height-45 ak-height-lg-30"></div>
                <h2 className="ak-section-title">About Us</h2>
                <h3>Driving dreams, delivering deals</h3>
                <p className="ak-section-subtitle">At Kayrah Cars, we are committed to provide an unparalleled
                    <b>Automobile</b> buying experience. As a <b>premier dealer</b> of high-end vehicles, we offer a curated
                    selection of the world's most prestigious car brands. Our aim is to match discerning clients
                    with their perfect vehicle, combining elegance, performance, and cutting-edge technology.
                </p>
                {/* </div> */}
                <div className="ak-height-60 ak-height-lg-30"></div>
                <Link
                    to={PageLinks.ABOUT_US}
                    className="text-hover-animation"
                    style={{ color: 'white' }}
                >
                    <div className="common-btn"> View More</div>
                </Link>

            </div>
            {/* end Services */}
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="ak-section-heading ak-style-1">
                        <div style={{ top: '10px' }}>Luxury cars & Vehicles:</div>
                        <h2 className="ak-section-title" style={{ width: '100vw' }}>Exquisite vehicles/ Premium vehicles</h2>
                        <div className="box">
                            <div className="category">
                                <img src="assets/img/maybach-brand-logo.jpg" alt="Maybach" className="image" />
                                <div className="text-center" style={{ marginTop: '10PX' }}>Maybach</div>
                            </div>

                            <div className="category">
                                <img src="assets/img/bmw.png" alt="BMW" className="image" />
                                <div className="text-center">BMW</div>
                            </div>

                            <div className="category">
                                <img src="assets/img/Ferrari.png" alt="Ferrari" className="image" />
                                <div className="text-center">Ferrari</div>
                            </div>

                            <div className="category">
                                <img src="assets/img/land-rover-brand-logo-car-symbol-white-design-vector-46088802.jpg" alt="Land rover"
                                    className="image" />
                                <div className="text-center" style={{ marginTop: '10PX' }}>Land rover</div>
                            </div>

                            <div className="category">
                                <img src="assets/img/benz.png" alt="Mercedes Benz" className="image" />
                                <div className="text-center">Mercedes Benz</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start Fun Fact Counter */}
            {/* Start Footer */}
            <Footer />
            {/* End Footer */}
            <span className="ak-scrollup">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10L1.7625 11.7625L8.75 4.7875V20H11.25V4.7875L18.225 11.775L20 10L10 0L0 10Z"
                        fill="currentColor" />
                </svg>
            </span>
            {/* Start Video Popup */}
        </>
    );
};

export default Index;
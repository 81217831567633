import React from 'react';
import { PageLinks } from '../common/Constants';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <>
            <div className="ak-height-45 ak-height-lg-40"></div>
            <footer className="footer style-1 footer-bg">
                <div className="container">
                    <div className="footer-content">
                        <div className="footer-info">
                            <div className="footer-logo" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="../assets/img/logo.png" alt="Kayrah Cars Logo" style={{ width: '100px', marginRight: '10px' }} />
                                <p className="desp">Let Kayrah Cars be your gateway to automotive excellence.</p>
                            </div>

                            <div className="ak-height-35 ak-height-lg-30"></div>
                            <div className="d-flex align-items-center gap-3">
                                <div className="heartbeat-icon">
                                    <a href="tel:+971585504939">
                                        <span className="ak-heartbeat-btn">
                                            <img src="../assets/img/phone.svg" alt="Phone Icon" />
                                        </span>
                                    </a>
                                </div>
                                <a href="tel:+971585504939" className="phone text-hover-animation white">
                                    +971 58 550 4939
                                </a>
                            </div>
                        </div>

                        <div className="footer-menu-one" data-aos-delay="50" data-aos-duration="500" style={{ margin: '30px' }}>
                            <div className="footer-menu">
                                <p className="menu-title">QUICK LINK</p>
                                <Link
                                    to={PageLinks.ABOUT_US}
                                    className="text-hover-animation"
                                    style={{ color: 'white' }}
                                >
                                    <div className="menu-text">About</div>
                                </Link>

                                <Link
                                    to={PageLinks.CONTACT_US}
                                    className="text-hover-animation"
                                    style={{ color: 'white' }}
                                >
                                    <div className="menu-text">Contact Us</div>
                                </Link>
                            </div>
                        </div>

                        <div className="footer-address" data-aos-delay="150" data-aos-duration="500" style={{ margin: '30px' }}>
                            <p className="address-title">ADDRESS</p>
                            <a href="#" className="location">
                                <span className="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
                                        <path
                                            d="M7.83533 0.501953C3.9756 0.501953 0.835327 3.4927 0.835327 7.16863C0.835327 8.27215 1.12502 9.36629 1.67574 10.3368L7.45253 18.2871C7.52943 18.4198 7.67598 18.502 7.83533 18.502C7.99467 18.502 8.14122 18.4198 8.21813 18.2871L13.9971 10.3335C14.5456 9.36629 14.8353 8.27211 14.8353 7.16859C14.8353 3.4927 11.6951 0.501953 7.83533 0.501953ZM7.83533 10.502C5.90546 10.502 4.33535 9.0066 4.33535 7.16863C4.33535 5.33066 5.90546 3.83531 7.83533 3.83531C9.76519 3.83531 11.3353 5.33066 11.3353 7.16863C11.3353 9.0066 9.76519 10.502 7.83533 10.502Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                                JT030047 Compass Building <br />
                                AL Hamra Industrial Zone - FZ <br />
                                Ras AI Khaimah, United Arab Emirates
                            </a>
                            <a href="mailto:Harsha@kayrahcars.com" className="email">
                                <span className="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"
                                        fill="none">
                                        <g clip-path="url(#clip0_365_2422)">
                                            <path
                                                d="M19.2194 16.3586C19.669 16.3586 20.0585 16.2102 20.3897 15.9171L14.7234 10.2505C14.5874 10.3479 14.4557 10.4426 14.3312 10.5326C13.9071 10.845 13.563 11.0888 13.2987 11.2635C13.0345 11.4386 12.6829 11.617 12.2441 11.7993C11.8049 11.9817 11.3958 12.0726 11.0163 12.0726H11.0051H10.994C10.6145 12.0726 10.2054 11.9817 9.76625 11.7993C9.32714 11.617 8.97557 11.4386 8.71159 11.2635C8.44736 11.0888 8.10338 10.845 7.67912 10.5326C7.56089 10.4459 7.42977 10.3508 7.28801 10.249L1.62061 15.9171C1.95173 16.2102 2.34153 16.3586 2.79106 16.3586H19.2194Z"
                                                fill="white" />
                                            <path
                                                d="M2.1326 6.68302C1.7086 6.40034 1.33259 6.07659 1.00525 5.71191V14.3331L5.99952 9.33882C5.00038 8.64128 3.71304 7.75703 2.1326 6.68302Z"
                                                fill="white" />
                                            <path
                                                d="M19.8892 6.68302C18.3691 7.71193 17.077 8.59771 16.013 9.34081L21.0053 14.3333V5.71191C20.6852 6.06925 20.3132 6.39278 19.8892 6.68302Z"
                                                fill="white" />
                                            <path
                                                d="M19.2194 0.644531H2.79109C2.21796 0.644531 1.77732 0.83807 1.46864 1.22475C1.15971 1.61161 1.00549 2.09542 1.00549 2.67563C1.00549 3.1443 1.21014 3.65211 1.61926 4.19921C2.02838 4.74609 2.46371 5.17565 2.92505 5.48811C3.17795 5.6668 3.94063 6.19701 5.21308 7.07858C5.89998 7.55458 6.49734 7.96947 7.01067 8.3275C7.44822 8.63237 7.82556 8.89639 8.13711 9.11549C8.17288 9.14058 8.22912 9.18081 8.30378 9.23419C8.38421 9.29196 8.48599 9.36527 8.61155 9.45594C8.85335 9.63081 9.05422 9.77217 9.21421 9.88016C9.37398 9.98819 9.56755 10.1089 9.79464 10.2428C10.0216 10.3766 10.2356 10.4773 10.4364 10.5442C10.6374 10.6111 10.8233 10.6446 10.9944 10.6446H11.0055H11.0167C11.1877 10.6446 11.3737 10.6111 11.5747 10.5442C11.7755 10.4773 11.9894 10.3769 12.2165 10.2428C12.4433 10.1089 12.6366 9.98793 12.7969 9.88016C12.9569 9.77217 13.1578 9.63085 13.3996 9.45594C13.5249 9.36527 13.6267 9.29192 13.7071 9.23437C13.7818 9.18077 13.838 9.1408 13.874 9.11549C14.1167 8.9466 14.4949 8.68367 15.0034 8.33059C15.9287 7.6877 17.2914 6.74146 19.0972 5.48811C19.6403 5.10877 20.0941 4.65099 20.4588 4.11544C20.8228 3.57989 21.0053 3.01812 21.0053 2.43031C21.0053 1.93921 20.8283 1.51898 20.4752 1.16897C20.1217 0.819406 19.703 0.644531 19.2194 0.644531Z"
                                                fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_365_2422">
                                                <rect width="21" height="17" fill="white"
                                                    transform="translate(0.835327 0.238281)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                Harsha@kayrahcars.com
                            </a>
                        </div>
                    </div>

                    <div className="ak-height-70 ak-height-lg-30"></div>
                    <div className="primary-color-border"></div>
                    <div className="copy-right">
                        <p className="title text-hover-animation">Copyright 2024, All Rights Reserved</p>
                        <div className="social-icon">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <g clipPath="url(#clip0)">
                                        <path
                                            d="M15.995 16.9761L15.999 16.9754L15.999 11.1074C15.999 8.23672 15.381 6.02539 12.025 6.02539C10.4117 6.025..."
                                            fill="white"
                                        />
                                    </g>
                                </svg>
                            </a>
                            {/* Add additional social icons here */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;

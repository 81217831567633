export const PageLinks = {
    INDEX: "/",
    ABOUT_US: "/about-us",
    PRODUCTS: "/products",
    PRODUCT_DETAIL: "/product-detail", 
    SERVICES: "/service",
    CONTACT_US: "/contact-us",
};

export const EndPoints = {
   
    VehicleCollectionTypes: "vehicle_collection_types",
    VehicleList: "vehicles",
    VehicleDetails: "vehicles",
    VehicleImages: "vehicle_images",
};

export const Configs = {
    // BaseUrl: import.meta.env.VITE_API_URL + 'api/',
    BaseUrl:'https://kayrahadmin.sitepreview.in/api/'
};
export type AlertType = "error" | "info" | "success" | "warning";

export const ProjectConfig = {
    VehicleImagePlaceholder: 'assets/images/no-image-placeholder.png',
}
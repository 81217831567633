import axios from "axios";
import { toast } from "react-hot-toast";

import { Configs, EndPoints } from "./Constants";

import { APIData } from "./DataTypes";

export enum APIMethod {
    GET = "GET",
}

export const blogList = async () => {
    return executeAPI(EndPoints.VehicleList, APIMethod.GET,);
}

export const getVehicleCollectionTypes = async (collectionTypeID?: number) => {
    let apiURL = EndPoints.VehicleCollectionTypes;
    if (collectionTypeID) {
        apiURL += `/${collectionTypeID}`;
    }
    return executeAPI(apiURL, APIMethod.GET);
}

export const GetVehicleFilter = async (filters?: APIData.FilterParams) => {
    const params: { [k: string]: string | number } = {};

    if (filters?.vehicle_collection_type_id) {
        params["vehicle_collection_type_id"] = filters.vehicle_collection_type_id;
    }
    if (filters?.vehicle_brand_id) {
        params["vehicle_brand_id"] = filters.vehicle_brand_id;
    }
    if (filters?.vehicle_body_type_id) {
        params["vehicle_body_type_id"] = filters.vehicle_body_type_id;
    }

    return executeAPI(EndPoints.VehicleList, APIMethod.GET, params);
};

export const GetVehicleFeatures = async (vehicleID: number) => {
    const params: { [k: string]: string | number } = {};
    params['vehicle_id'] = vehicleID;
    return executeAPI(EndPoints.VehicleList, APIMethod.GET, params);
}


export const GetVehicleImages = async (vehicleID: number) => {
    const params: { [k: string]: string | number } = {};
    params['vehicle_id'] = vehicleID;
    return executeAPI(EndPoints.VehicleImages, APIMethod.GET, params)
}
export function GetVehicleById(vehicleID: number) {
    return executeAPI(EndPoints.VehicleList, APIMethod.GET)
}
export const getVehicleDetail = async (vehicleID: number) => {
    const params: { [k: string]: string | number } = {};
    return executeAPI(EndPoints.VehicleDetails + '/' + vehicleID, APIMethod.GET);
}
export const getVehicleDetail1 = async (vehicleID: number) => {
    const params: { [k: string]: string | number } = {};
    params['detailed'] = 1;
    return executeAPI(EndPoints.VehicleDetails + '/' + vehicleID, APIMethod.GET, params);
}
export const executeAPI = async (
    endpoint: string,
    method: APIMethod,
    params?: { [k: string]: string | number }
) => {

    const headers: { [k: string]: string } = {};

    try {
        const response = await axios({
            method: method,
            url: Configs.BaseUrl + endpoint,
            headers: headers,
            params: params,
        });
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return response.data;
        } else if (response.status === 301) {
            toast.error("The resource you're looking for has moved.");
        } else if (response.status === 400) {
            toast.error("Your request is invalid. Please enter valid data and try again.");
        } else if (response.status === 401) {
            toast.error("You're not authorized to access this resource. Please log in.");
        } else if (response.status === 403) {
            toast.error("You don't have permission to access this resource.");
        } else if (response.status === 404) {
            toast.error("The resource you're looking for was not found.");
        } else if (response.status === 405) {
            toast.error("The HTTP method you're trying to use isn't supported by this resource.");
        } else if (response.status === 406) {
            toast.error("The resource cannot produce a representation matching the list of acceptable values.");
        } else if (response.status === 408) {
            toast.error("Your request timed out. Please try again.");
        } else if (response.status === 409) {
            toast.error("Your request could not be completed due to a conflict with the current state of the resource");
        } else if (response.status === 410) {
            toast.error("The resource is no longer available");
        } else if (response.status === 429) {
            toast.error("You've made too many requests in a short amount of time. Please wait and try again.");
        } else if (response.status === 500) {
            toast.error("Something went wrong on our end. Please try again later.");
        } else if (response.status === 501) {
            toast.error("The functionality you're trying to access is not supported.");
        } else if (response.status === 502) {
            toast.error("We're experiencing problems. Please try again later.");
        } else if (response.status === 503) {
            toast.error("Our service is temporarily unavailable. Please try again later.");
        } else if (response.status === 504) {
            toast.error("The gateway has timed out. Please try again.");
        } else {
            if (response.data.message) {
                toast.error(response.data.message);
            }
            console.log("Error", response.status, response);
        }
    } catch (error: any) {
        const response = error.response;
        if (response.status === 301) {
            toast.error("The resource you're looking for has moved.");
        } else if (response.status === 400) {
            toast.error("Your request is invalid. Please enter valid data and try again.");
        } else if (response.status === 401) {
            toast.error("You're not authorized to access this resource. Please log in.");
        } else if (response.status === 403) {
            toast.error("You don't have permission to access this resource.");
        } else if (response.status === 404) {
            toast.error("The resource you're looking for was not found.");
        } else if (response.status === 405) {
            toast.error("The HTTP method you're trying to use isn't supported by this resource.");
        } else if (response.status === 406) {
            toast.error("The resource cannot produce a representation matching the list of acceptable values.");
        } else if (response.status === 408) {
            toast.error("Your request timed out. Please try again.");
        } else if (response.status === 409) {
            toast.error("Your request could not be completed due to a conflict with the current state of the resource");
        } else if (response.status === 410) {
            toast.error("The resource is no longer available");
        } else if (response.status === 429) {
            toast.error("You've made too many requests in a short amount of time. Please wait and try again.");
        } else if (response.status === 500) {
            toast.error("Something went wrong on our end. Please try again later.");
        } else if (response.status === 501) {
            toast.error("The functionality you're trying to access is not supported.");
        } else if (response.status === 502) {
            toast.error("We're experiencing problems. Please try again later.");
        } else if (response.status === 503) {
            toast.error("Our service is temporarily unavailable. Please try again later.");
        } else if (response.status === 504) {
            toast.error("The gateway has timed out. Please try again.");
        }
        console.log("error", error);
        if (error.response && error.response.data) {
            toast.error(error.response.data);
        } else if (error.message) {
            toast.error(error.message);
        } else {
            toast.error("Error occurred while processing the request");
        }
    }
}
export function GetVehicleDetails(vehicleID: number) {
    throw new Error('Function not implemented.');
}




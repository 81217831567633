// Header.tsx
import React from 'react';
import { PageLinks } from '../common/Constants';
import NavMenu from '../components/Menu';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    return (
        <header className="ak-site_header ak-style1 ak-sticky_header">
            <div className="ak-main_header">
                <div className="container">
                    <div className="ak-main_header_in">
                        <div className="ak-main-header-left">
                            <Link
                                to={PageLinks.INDEX}
                                className="text-hover-animation"
                                style={{ color: 'white' }}
                            >
                                <div className="ak-site_branding">
                                    <img src="/assets/img/logo.png" alt="Logo" />
                                </div>
                            </Link>
                        </div>
                        <NavMenu />
                        <div className="ak-main-header-right">
                            <a href="tel:+971 58 550 4939">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="heartbeat-icon">
                                        <span className="ak-heartbeat-btn">
                                            <img src="../assets/img/phone.svg" alt="..." />
                                        </span>
                                    </div>
                                    <h6>+971 58 550 4939</h6>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-bar-border"></div>
        </header>
    );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { PageLinks } from '../common/Constants';
import { Link } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';
import * as ServerAPI from '../common/ServerAPI';
import { slide as Menu } from 'react-burger-menu';

const NavMenu: React.FC = () => {
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [collectionTypes, setCollectionTypes] = useState<APIData.VehicleCollectionType[]>([]);

    useEffect(() => {
        fetchCollectionTypes();
    }, []);

    const fetchCollectionTypes = async () => {
        addProcessingRequests();
        try {
            const response = await ServerAPI.getVehicleCollectionTypes();
            if (response.success && response.vehicle_collection_types) {
                setCollectionTypes(response.vehicle_collection_types);
            } else if (response.message) {
                showToastMsg("error", response.message);
            } else {
                showToastMsg("error", "Error occurred while processing the request");
            }
        } catch (error) {
            showToastMsg("error", "An unexpected error occurred");
        } finally {
            reduceProcessingRequests();
        }
    };

    return (
        <nav className="ak-main-header-center">
            <div className="ak-nav ak-medium">
                <ul className="ak-nav_list">
                    <li>
                        <Link to={PageLinks.INDEX} className="text-hover-animation">Home</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.ABOUT_US} className="text-hover-animation">About</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.PRODUCTS} className="text-hover-animation">Cars</Link>
                        {/* <ul className="dropdown-menu" style={{ color:"white" }}>
                            {collectionTypes.map((collectionType, index) => (
                                <li key={index}>
                                    <Link to={`${PageLinks.PRODUCTS}?collection_type_id=${collectionType.vehicle_collection_type_id}`} className="text-hover-animation">
                                        {collectionType.vehicle_collection_type_name} Collections
                                    </Link>
                                </li>
                            ))}
                        </ul> */}
                    </li>
                    <li>
                        <Link to={PageLinks.CONTACT_US} className="text-hover-animation">Contact Us</Link>
                    </li>
                </ul>
                <div className='mobile_menu'>
                    <Menu>
                        <Link to={PageLinks.INDEX} className="text-hover-animation">Home</Link>
                        <Link to={PageLinks.ABOUT_US} className="text-hover-animation">About</Link>
                        <Link to={PageLinks.PRODUCTS} className="text-hover-animation">Cars</Link>
                        <Link to={PageLinks.CONTACT_US} className="text-hover-animation">Contact Us</Link>
                    </Menu>
                </div>
            </div>
        </nav>
    );
};

export default NavMenu;

import React, { useState } from 'react';
import DOMPurify from 'dompurify'; // Ensure you have DOMPurify installed

interface DescriptionProps {
    htmlContent: string;
    maxLength: number; 
}

const Description: React.FC<DescriptionProps> = ({ htmlContent, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // Sanitize the HTML content
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);

    // Check if the description is longer than the maxLength
    const isLongText = sanitizedHTML.length > maxLength;

    // Toggle the visibility of the extra text
    const toggleReadMore = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault(); // Prevent default link behavior
        setIsExpanded((prev) => !prev);
    };

    return (
        <div>
            {/* Display the appropriate amount of text based on expansion */}
            <div
                className="description-content"
                dangerouslySetInnerHTML={{
                    __html: isExpanded ? sanitizedHTML : `${sanitizedHTML.slice(0, maxLength)}${isLongText ? '...' : ''}`,
                }}
            />

            {/* Only show "Read more" if the text is long */}
            {isLongText && (
                <div className="more-button">
                    <a
                        href="#"
                        className="more-btn"
                        aria-label={isExpanded ? 'Read less' : 'Read more'}
                        onClick={toggleReadMore}
                    >
                        {isExpanded ? 'Read less' : 'Read more'}
                    </a>
                </div>
            )}
        </div>
    );
};

export default Description;
